import { StarIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';

export interface CardAuthorBoxProps {
  className?: string;
  data: Agent;
  index?: number;
}

const CardAuthorBox: FC<CardAuthorBoxProps> = ({
  className = '',
  data,
  index,
}) => {
  return (
    <div
      className={`nc-CardAuthorBox relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardAuthorBox"
    >
      {index && (
        <Badge
          className="absolute left-3 top-3"
          color={index === 1 ? 'red' : index === 2 ? 'blue' : 'green'}
          name={`#${index}`}
        />
      )}
      <Avatar
        sizeClass="w-20 h-20 text-2xl"
        radius="rounded-full"
        userName={data.username}
      />
      <div className="mt-3">
        <h2 className={`text-base font-medium`}>
          <span className="line-clamp-1">{data.username}</span>
        </h2>
        <span
          className={`block mt-1.5 text-sm text-neutral-500 dark:text-neutral-400`}
        >
          New York
        </span>
      </div>
      <div className="py-2 px-5 mt-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ">
        <span className="text-xs font-medium pt-[1px]">
          4.9
          {/* {starRating || 4.9} */}
        </span>
        <StarIcon className="w-5 h-5 text-amber-500 ml-2 " />
      </div>
    </div>
  );
};

export default CardAuthorBox;
