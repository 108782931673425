import logoLightImg from 'images/logo-light.png';
import logoImg from 'images/logo.png';
import React from 'react';
import { Link } from 'react-router-dom';

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = 'w-32',
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >
      {img ? (
        <img
          className={`block max-h-12 ${imgLight ? 'dark:hidden' : ''}`}
          src={img}
          alt="Logo"
        />
      ) : (
        'Logo Here'
      )}
      {imgLight && (
        <img
          className="hidden max-h-12 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )}
    </Link>
  );
};

export default Logo;
